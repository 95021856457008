/**
 * @name useFetch
 * @description hooks기반 fetch
 * @refer https://developer.mozilla.org/ko/docs/Web/API/Fetch_API/Using_Fetch
 */

import {useState} from 'react'

export const useFetch = (_url?: string, _options = {}) => {
  const [result, setResult] = useState<string | boolean>('') // *--------------------- result
  const [loading, setLoading] = useState<boolean | null>(null) // *--------- Loading
  const [error, setError] = useState<any>(null) // *------------------------ Error

  // *---------------- response
  async function response(_config: any) {
    setLoading(true)
    const config = Object.assign(
      {
        // headers: {'Content-type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${token}`},
        // headers: {'Content-type': 'multipart/form-data'},
        headers: {
          // 'Content-type': 'application/json; charset=utf-8',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          // 'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        // mode: 'no-cors',
        async: true,
        crossDomain: true,
        withCredentials: true,
      },
      _config,
    )
    const res = await fetch(config?.url, config)
    const json = await res.json()
    // console.log(res)
    setLoading(false)
    if (res?.status === 200 || res?.status === 201) {
      // REST성공
      setResult(json?.isSuccess)
      return json
    } else {
      // rest실패
      setError(json)
      return json
    }
  }
  // *---------------- Error 실행
  function errorFunc(error: any) {
    setLoading(false)
    setError(error)
    errorMsg({...(error as any)})
  }
  // *-------------------------------------- FETCH
  async function ajax(url?: string | null, params?: {}) {
    const config = {method: 'GET', url: url || _url, ...params}
    try {
      return await response(config)
    } catch (error) {
      errorFunc(error)
    }
  }
  // *-------------------------------------- GET
  async function get(url?: string | null, params?: any) {
    let fetchUrl = url || _url
    if (!!params) {
      const query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      fetchUrl = fetchUrl + '?' + query
    }
    const config = {method: 'GET', url: fetchUrl}
    try {
      return await response(config)
    } catch (error) {
      errorFunc(error)
    }
  }
  // *-------------------------------------- POST
  async function post(url?: string | null, data?: {} | any) {
    // const form = new FormData()
    // for (const [key, value] of Object.entries(data)) {
    //   const _key: string = key
    //   const _value: any = value
    //   form.append(_key, _value)
    // }
    const config = {
      method: 'POST',
      url: url ?? _url,
      body: new URLSearchParams(data),
      // body: JSON.stringify(Object.assign(_options, data)), // body의 데이터 유형은 반드시 "Content-Type" 헤더와 일치해야 함
    }
    try {
      return await response(config)
    } catch (error) {
      errorFunc(error)
    }
  }
  // *-------------------------------------- PUT
  async function put(url?: string | null, data?: {}) {
    const config = {
      method: 'PUT',
      url: url || _url,
      body: JSON.stringify(Object.assign(_options, data)),
    }
    try {
      return await response(config)
    } catch (error) {
      errorFunc(error)
    }
  }
  // *-------------------------------------- DELETE
  async function del(url?: string | null, data?: {}) {
    const config = {
      method: 'DELETE',
      url: url || _url,
      body: JSON.stringify(Object.assign(_options, data)),
    }
    try {
      return await response(config)
    } catch (error) {
      errorFunc(error)
    }
  }
  // *--------------------------------------
  return {result, ajax, get, post, del, put, loading, error}
}

/**
 * @description 에러발생시코드
 * @param error
 */
export const errorMsg = (...params: any) => {
  console.log(`%c [ajax ERROR] `, `width:100%;font-size:12px;padding:5px 10px;color:#fff;background:red;`)
  console.log(JSON.stringify(params, null, 1))
}
/********************************************************
[사용법]
  const { get, loading } = useFetch(`${USER}/community/join/board`, { offset: 0 });
  //*------- fetch
  async function fetch() {
    const res = await get()
    const res1 = await get(`${USER}/community/join/board/some`, { offset: 20 })
  }
  // useEffect
  useEffect(() => { fetch(); }, [])
  post, delete, put 일때 success, fail 체크는 
  if (res?.result === 'fail') {  }

*********************************************************/
