// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui_btn__mJGJ9 {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: #fff;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.4);
  background-color: #151529;
}

.ui_input__nQx6U {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  border: 0;
  padding: 0 12px;
  border-radius: 8px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  outline: none;
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
}`, "",{"version":3,"sources":["webpack://./src/styles/modules/ui.module.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,sBAAA;EACA,2CAAA;EACA,yBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,qDAAA;AAAF","sourcesContent":["//*--------------------------------------------------* 버튼\n.btn {\n  display: inline-block;\n  padding: 0.5rem 1rem;\n  border-radius: 4px;\n  color: #fff;\n  border: 0;\n  box-sizing: border-box;\n  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.4);\n  background-color: #151529;\n}\n// 텍스트\n.input {\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  width: 100%;\n  height: 52px;\n  border: 0;\n  padding: 0 12px;\n  border-radius: 8px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: -0.5px;\n  outline: none;\n  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);\n  // background-color: var(--COLOR_GRAY6);\n  // &:focus {\n  //   border: 1px solid var(--COLOR_BLACK);\n  //   background-color: var(--COLOR_GRAY6);\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ui_btn__mJGJ9`,
	"input": `ui_input__nQx6U`
};
export default ___CSS_LOADER_EXPORT___;
