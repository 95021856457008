/**
 * @name Lottie
 * @description https://react-icons.github.io/react-icons/icons?name=vsc
 */

/**---------------------------------- @name common */
export * from 'react-icons/ai'
export * from 'react-icons/bs'
export * from 'react-icons/bi'
export * from 'react-icons/ci'
export * from 'react-icons/di'
export * from 'react-icons/fi'
export * from 'react-icons/fa'
export * from 'react-icons/fc'
export * from 'react-icons/gi'
export * from 'react-icons/go'
export * from 'react-icons/gr'
export * from 'react-icons/hi'
export * from 'react-icons/im'
export * from 'react-icons/io5'
export * from 'react-icons/md'
export * from 'react-icons/rx'
export * from 'react-icons/ri'
export * from 'react-icons/si'
export * from 'react-icons/sl'
export * from 'react-icons/tb'
export * from 'react-icons/tfi'
export * from 'react-icons/ti'
export * from 'react-icons/vsc'
export * from 'react-icons/cg'

/**
[사용법]

import {FaUser, IoApps} from 'assets/icon'

<IoApps color={COLOR.GRAY1} />

 */
