/**
 * @name Redux_Toolkit
 * @description 템플릿가이드를 최상단으로 고정필요, 하면 이슈생심
 */

/*===================================================================[가이드] */
/** @name 가이드 */
export {
  default as guideSlice,
  actions as guideAction,
  EVENT as GUIDE_EVENT,
} from "./_guide";

/** @name 컨텐츠 */
/*===================================================================[계정관리] */
export {
  default as mapSlice,
  actions as mapAction,
  EVENT as MAP_EVENT,
  POPUP as MAP_POPUP,
} from "./map";
export { default as contextSlice, actions as contextAction } from "./context";

/********************************************************
[사용법]
export {
  default as guideSlice, 
  actions as guideAction,
  EVENT as GUIDE_EVENT
  }
  from './_guide'
*********************************************************/
