/**
 * @name Footer
 * @description
 */
import { Row, Text, TextButton, useRoutes } from "lib";
import styled from "styled-components";
import { COLOR } from "styles";
import { MENU } from "config";

export default function Contents() {
  // hooks
  const { navigate } = useRoutes();
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="space_between">
        <Text color={COLOR.GRAY2} className="body1" style={{ fontSize: 14 }}>
          © 2023 ㈜크로커스 All rights reserved
        </Text>
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  border-top: 1px solid ${COLOR.GRAY5};
  padding: 20px 24px;

  /* margin-left: 24px; */
  /* margin-right: 24px; */
  /* margin-bottom: 30px; */
`;
