/**
 * @name useDragAndDrop
 * @description 드래그앤드롭
 * @추천 https://www.npmjs.com/package/react-drag-drop-files
 * @refer https://velog.io/@yiyb0603/React%EC%97%90%EC%84%9C-%EB%93%9C%EB%9E%98%EA%B7%B8-%EC%95%A4-%EB%93%9C%EB%A1%AD%EC%9D%84-%EC%9D%B4%EC%9A%A9%ED%95%9C-%ED%8C%8C%EC%9D%BC-%EC%97%85%EB%A1%9C%EB%93%9C-%ED%95%98%EA%B8%B0
 */

import {useState, useEffect, useCallback} from 'react'

export const useDragAndDrop = ({onChange, dragRef}: any) => {
  const [isDragging, setIsDragging] = useState(false)
  const handleDragIn = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDragOut = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)
  }, [])

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer.files) {
      setIsDragging(true)
    }
  }, [])

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      onChange(e)
      setIsDragging(false)
    },
    [onChange],
  )

  const initDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener('dragenter', handleDragIn)
      dragRef.current.addEventListener('dragleave', handleDragOut)
      dragRef.current.addEventListener('dragover', handleDragOver)
      dragRef.current.addEventListener('drop', handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  const resetDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener('dragenter', handleDragIn)
      dragRef.current.removeEventListener('dragleave', handleDragOut)
      dragRef.current.removeEventListener('dragover', handleDragOver)
      dragRef.current.removeEventListener('drop', handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  useEffect(() => {
    initDragEvents()
    return () => resetDragEvents()
  }, [initDragEvents, resetDragEvents])
  return isDragging
}
