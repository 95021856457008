/**
 * @name Test
 * @description
 */
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
// contents
import {Header, Footer, Code1, Code2} from './contents'

export default function Index() {
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Panel header={<Header />} footer={<Footer />}>
          <Code1 />
          <Code2 />
        </Panel>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
