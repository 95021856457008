// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.button_defaultButton__ntQRA {
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid var(--Blue-blue-3, #4073f4);
  background: var(--Blue-blue-3, #4073f4);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
}
.button_defaultButton__ntQRA:hover {
  background: var(--Blue-blue-1, #1850dd);
}
.button_defaultButton__ntQRA:active {
  background: var(--Blue-blue-0, #1146cb);
}
.button_defaultButton__ntQRA:disabled {
  cursor: not-allowed;
}

.button_red__ki\\+Ng {
  background-color: #ff0000;
}
.button_red__ki\\+Ng:hover {
  color: blue;
}

.button_large__7vmVj {
  font-size: 30px;
}

/********************************************************
[사용법]

*********************************************************/`, "",{"version":3,"sources":["webpack://./src/styles/themes/button.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,uCAAA;EACA,sBAAA;EACA,oDAAA;AACF;AAAE;EACE,uCAAA;AAEJ;AAAE;EACE,uCAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;;AAEA;EAEE,yBAAA;AAAF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,eAAA;AAAF;;AAEA;;;yDAAA","sourcesContent":["//*--------------------------------------------------* 버튼\n.defaultButton {\n  display: inline-flex;\n  padding: 8px 20px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  color: #fff;\n  border-radius: 8px;\n  border: 1px solid var(--Blue-blue-3, #4073f4);\n  background: var(--Blue-blue-3, #4073f4);\n  box-sizing: border-box;\n  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);\n  &:hover {\n    background: var(--Blue-blue-1, #1850dd);\n  }\n  &:active {\n    background: var(--Blue-blue-0, #1146cb);\n  }\n  &:disabled {\n    cursor: not-allowed;\n  }\n}\n//*--------------------------------------------------* 색상관련\n.red {\n  // color: red;\n  background-color: #ff0000;\n  &:hover {\n    color: blue;\n  }\n}\n//*--------------------------------------------------* 사이즈관련\n.large {\n  font-size: 30px;\n}\n/********************************************************\n[사용법]\n\n*********************************************************/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultButton": `button_defaultButton__ntQRA`,
	"red": `button_red__ki+Ng`,
	"large": `button_large__7vmVj`
};
export default ___CSS_LOADER_EXPORT___;
