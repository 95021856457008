/**
 * @name Images
 */

/**---------------------------------- @name Common */
export const logo = require('./common/acelo.png')
export const auth_logo = require('./common/auth_logo.png')
export const crocus = require('./common/crocus.png')
export const acelo = require('./common/acelo.png')

/**---------------------------------- @name Icon */
export const map_marker1 = require('./icon/map_marker1.png')
export const map_marker2 = require('./icon/map_marker.png')
export const small_location = require('./icon/small_location.png')
export const small_phone = require('./icon/small_phone.png')

/**---------------------------------- @name Pages */
export const map_logo = require('./pages/map_logo.png')
export const map_marker = require('./pages/map_marker.png')
export const map_marker_hover = require('./pages/map_marker_hover.png')
export const map_marker_active = require('./pages/map_marker_active.png')
//충전기
export const ev_marker = require('./pages/ev_marker.png')
export const ev_marker_hover = require('./pages/ev_marker_hover.png')
export const ev_marker_active = require('./pages/ev_marker_active.png')
//올리바인
export const olivine_marker = require('./pages/olivine_marker.png')
export const olivine_marker_hover = require('./pages/olivine_marker_hover.png')
export const olivine_marker_active = require('./pages/olivine_marker_active.png')

export const sp_link = require('./pages/sp_link.png')
export const sp_logout = require('./pages/sp_logout.png')
