/**
 * @name useClickOutside
 * @description
 */

import React, {useCallback, useEffect, useState} from 'react'

export const useDebounce = (func: any, delay: number, deps: any) => {
  const callback = useCallback(func, deps)

  useEffect(() => {
    const timer = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [callback, delay])
}
/********************************************************
import React, { useRef } from 'react'

import { useOnClickOutside } from 'usehooks-ts'

useDebounce(() => console.log(value), 1000, [value]);

*********************************************************/
