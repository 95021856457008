/**
 * @name useObject
 * @description
 */
import {useCallback, useState} from 'react'

// Hook
export const useObject = (initialState: {} = {}): any => {
  // Initialize the state
  const [state, setState] = useState<object>(initialState)
  const update = useCallback(
    (data: any): void =>
      setState(prevState => {
        return {...prevState, ...data}
      }),
    [],
  )
  return [state, update]
}
/********************************************************
[사용법]
import { useObject} from 'lib'

const [info, setInfo] = useObject({ id: '', pw: '', })

<input defaultValue={info?.id} className={`${ui.input} input`} type="text" placeholder="아이디를 입력해주세요"
  onChange={(event:any)=> {
    const {value} = event.target
    setInfo({id: value})
  }}
/>
*********************************************************/
