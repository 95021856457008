/**
 * @name DropDown
 * @description
 */
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {List, useClickOutside, uuid} from 'lib'
import {CSS, ListRenderItem} from 'types'
import {FcCalendar, FcCrystalOscillator, FcFactoryBreakdown, FcSerialTasks, FcSettings, FcSupport, IoApps} from 'assets/icon'
import {COLOR, FONT} from 'styles'

type ItemProps = {
  label?: string
  value: any
}
type Props = {
  info: ItemProps[] //----------------------------------------------- 정보
  disabled?: boolean //---------------------------------------------- 비활성화
  onChange?: (item: ItemProps) => void //---------------------------- onChange변화값
  style?: CSS //----------------------------------------------------- inputStyleSheet
  layerStyle?: CSS //------------------------------------------------ 레이어팝업 StyleSheet
  visible?: boolean //----------------------------------------------- 레이어팝업 show/hide 외부에서컨트롤
  renterItem?: (item: ListRenderItem) => React.ReactNode //----------- 렌더링 아이템
  // [key: string]: any
}
export default function TopUtilGroup() {
  // hooks
  const eleRef = useRef(null)
  const [isBool, setIsBool] = useState(false)
  // outside click
  useClickOutside(eleRef, () => {
    setIsBool(false)
  })
  //*--------------------------------------------------*
  return (
    <Element ref={eleRef}>
      <button
        onClick={() => {
          setIsBool(true)
        }}>
        <IoApps color={COLOR.GRAY1} />
      </button>
      {/* 레이어팝업 */}
      {isBool && (
        <article className="popup">
          <div className="wrap">
            {/* 메뉴단위 */}
            <button
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcCalendar size={20} />
              <label>스케줄</label>
            </button>
            {/* 메뉴단위 */}
            <button
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcSupport size={20} />
              <label>수리현황</label>
            </button>
            {/* 메뉴단위 */}
            <button
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcSerialTasks size={20} />
              <label>모터진단</label>
            </button>
            {/* 메뉴단위 */}
            <button
              className="disabled"
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcFactoryBreakdown size={20} />
              <label>전압체크</label>
            </button>
            {/* 메뉴단위 */}
            <button
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcCrystalOscillator size={20} />
              <label>전력측정</label>
            </button>
            {/* 메뉴단위 */}
            <button
              key={uuid()}
              onClick={() => {
                console.log('test')
              }}>
              <FcSettings size={20} />
              <label>전력측정</label>
            </button>
          </div>
        </article>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  position: relative;

  /* <!--레이어팝업--> */
  .popup {
    position: absolute;
    top: 40px;
    left: -120px;
    z-index: 1;
    width: 330px;
    height: 230px;
    padding: 10px;
    border: 1px solid ${COLOR.GRAY6};
    /* padding: 10px; */
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    background-color: ${COLOR.WHITE};
    //
    .wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    //
    button {
      display: flex;
      width: 60px;
      height: 60px;
      margin: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: ${COLOR.GRAY6};
      flex-direction: column;
      &.disabled {
        background: ${COLOR.BLUE0};
        label {
          color: ${COLOR.WHITE};
        }
      }
      label {
        display: block;
        padding-top: 6px;
        color: ${COLOR.GRAY1};
        font-family: ${FONT.MAIN};
        font-size: 12px;
      }
    }
  }
`
/********************************************************
[사용법]


*********************************************************/
