/**
 * @name App
 * @description
 */
import React, { useEffect } from "react";
import styled from "styled-components";
import { layout } from "styles";
import { isEmptyObject, useContext } from "lib";
import { CSS } from "types";
// components
import Footer from "./footer";
import Top from "./top";
type Props = {
  loading?: boolean | null; // 로딩중
  padding?: number | string; // 패딩
  style?: CSS; // 스타일
  children?: React.ReactNode | JSX.Element[] | undefined;

  [key: string]: any;
};
export default function App({ children, padding, style }: Props) {
  // hooks
  const { user } = useContext();
  // const _padding = padding !== undefined ? {padding: padding} : {padding: 24}
  // const _style: CSS = Object.assign({}, _padding)
  //*--------------------------------------------------*
  // refreshLogin
  async function refreshLogin() {
    // const _email = await sessionStorage.getItem(SESSION_STORAGE.EMAIL)
    // const _password = await sessionStorage.getItem(SESSION_STORAGE.PASSWORD)
    // 로그인갱신
  }
  // useEffect
  useEffect(() => {
    if (isEmptyObject(user)) refreshLogin();
  }, []);
  //*--------------------------------------------------*
  return (
    <Content style={style} padding={padding} className={layout.contents}>
      {/* gnb */}
      {/* container */}
      <article className={layout.container}>
        {/* top */}
        <div className={layout.top}>
          <Top />
        </div>
        {/* main */}
        <main>
          <div className={`children ${layout.children}`}>{children}</div>
          {/* footer */}
          <footer>
            <Footer />
          </footer>
        </main>
      </article>
    </Content>
  );
}
const Content = styled.main<Props>`
  /* 컨텐츠 */
  display: flex;
  flex-direction: row;
  /* overflow-x: hidden; */
  /* GNB */
  > aside {
  }
  /* article */
  article > main .children {
    box-sizing: border-box;
    padding: ${({ padding }) => padding || "24px"};
    /* height: var(--CONTAINER_HEIGHT); */ /* 패널형태 */
  }
`;
/********************************************************
[사용법]
<App padding={'100px 20px'}>
...
</App>
*********************************************************/
