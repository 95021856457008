/**---------------------------------- @name API */
const DEV_API = 'https://dummyjson.com'

// export const API = process.env.NODE_ENV === 'development' ? DEV_API : PROD_API
export const API = DEV_API

/**---------------------------------- @name API_KEY */
export const GOOGLE_MAP_KEY = 'AIzaSyArzPXNGE4Xwx7mpmSkjIQNIbhHZv0yK_A'

/**---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = 'e897d5472f242a09a1c4ba9b357ff37a'

/********************************************************
[사용법]
구글 크로커스계정

*********************************************************/
