import * as To from "pages";
import { GnbMenuType, MenuRoutesType } from "types";

/*===================================================================[메뉴] */
export const MENU = {
  /*---공통---------*/
  HOME: "/",
  TEST: "/test",
  SETTING: "/setting",
  /*---ETC---------*/
  SAMPLE: "/sample",
  TEMPLATE: "/template",
  //*--------------------------------------------------*
};
/*===================================================================[라우팅] */
export const MenuRoutes: MenuRoutesType[] = MENU && [
  /*---홈---------*/
  { path: MENU.HOME, element: <To.Home /> },
  /*---설정---------*/
  { path: MENU.TEST, element: <To.Test /> },

  /*---공통---------*/
];
/*===================================================================[GNB] */
export const GNB_MENU: GnbMenuType[] = MENU && [];
