/**
 * @name Text
 * @description Text
 */
import styled from 'styled-components'
import {COLOR, FONT} from 'styles'
import {TextProps} from 'types'

export default function Text({children, color, font, size, label, align, weight, style, ...props}: TextProps) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Element {...props} style={style} font={font} align={align} size={size} color={color} weight={weight}>
      {!!label && label}
      {children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.span<TextProps>`
  font-family: ${({font}) => font || FONT.MAIN};
  font-size: ${({size}) => size || 'initial'};
  font-weight: ${({weight}) => weight || 'normal'};
  text-align: ${({align}) => align || 'left'};
  color: ${({color}) => color || COLOR.BLACK};
`
/********************************************************
[사용법]

<Text size="30px" label={`width : ${width}`} align="right" />
<Text size="14px" weight={400} color={COLOR.GRAY1} label={`width : ${width}`} align="left" style={{padding: 20}} />

*********************************************************/
