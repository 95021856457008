/**
 * @name TextButton
 * @description 버튼
 */
import {useState} from 'react'
import {ButtonProps} from 'types'
// contents
import Button from './button'

type Props = {
  width?: string | number //----------------------이미지넓이
  height?: string | number //---------------------이미지높이
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src'] //-----------이미지속성
  hover_src?: React.ImgHTMLAttributes<HTMLImageElement>['src'] //---- hover 시 이미지 바꾸는 속성 추가
}
type ImageButtonProps = ButtonProps & Props

export default function ImageButton(props: ImageButtonProps) {
  const [isHovered, setIsHovered] = useState(false) //---- hover 여부 상태 추가
  return (
    <Button
      onMouseEnter={() => {
        setIsHovered(true)
      }} //---------------------------------------- 마우스 오버 시 상태 변경
      onMouseLeave={() => {
        setIsHovered(false)
      }} //---------------------------------------- 마우스 벗어날 때 상태 변경
      style={{fontSize: 0}}
      {...props}>
      <img
        style={{
          width: props?.width,
          height: props?.height,
          objectFit: 'cover',
        }}
        src={isHovered && props?.hover_src ? props?.hover_src : props?.src} //---- hover 시 이미지 변경
        alt={props?.alt || 'imagebutton'}
      />
    </Button>
  )
}

/********************************************************
[사용법]
  <Button.Image
    width={200}
    height={70}
    src="https://source.unsplash.com/random/?"
    hover_src=" https://source.unsplash.com/random/?seoul"
    onClick={event => {
      console.log(event)
    }}
  />
*********************************************************/
