/**
 * @name Container
 */
import {useEffect, useRef} from 'react'
import {Events, getLatLng, googleMapInfoWindowClose, useInterval} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {MAP_EVENT} from 'model/modules'
import {_action} from '.'

// contents
import Presenter from './presenter'

export default function Container() {
  // hooks
  // hooks
  const mapRef = useRef<any>(null)
  const {isAuto, selected} = useAppSelector(state => state.map)
  const dispatch = useAppDispatch()

  //*--------------------------------------------------*
  useInterval(
    async () => {
      let temp = selected + 1
      temp %= window.googlemap.positions.length
      const latlng = await getLatLng(window.googlemap.positions[temp]?.address)
      window.googlemap.panTo(latlng)
      googleMapInfoWindowClose(temp)
      dispatch(_action.setSelected(temp))
    },
    isAuto ? 1000 * 5 : null,
  )
  // onInit
  async function onInit() {}
  /**
   * @description 구글맵클릭이벤트
   */
  async function onMapClick(data: any) {
    console.log(data)
  }
  useEffect(() => {
    console.log(isAuto)
  }, [isAuto])
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener('init', onInit)
    Events.addListener(MAP_EVENT.MAP_CLICK, onMapClick)
    return () => {
      // removeListener
      Events.removeListener('init', onInit)
      Events.removeListener(MAP_EVENT.MAP_CLICK, onMapClick)
    }
  }, [])

  return <Presenter mapRef={mapRef} />
}
