/**
 * @name useAnimatedCount
 * @description 숫자의 애니메이션 증가를 위한 커스텀 훅입니다.
 */

import {useEffect, useState} from 'react'

type Props = {
  from: number //--------- 애니메이션 시작 숫자
  to: number //----------- 애니메이션 종료 숫자
  duration: number //----- 애니메이션 총 지속 시간 (ms)
  max_step?: number //---- 최대 증가 단위
}
export const useAnimatedCount = ({from, to, duration, max_step}: Props): string => {
  const [count, setCount] = useState(from)
  const maxStep = 10 // 최대 증가 단위
  const step = Math.min(Math.abs((to - from) / (duration / (1000 / 60))), maxStep) // 각 프레임에서의 증가 단위 계산

  useEffect(() => {
    if (from > to) {
      console.log(' from>to 애니메이션중지')
      return
    }
    let currentCount = from
    const intervalId = setInterval(() => {
      currentCount += step
      if (currentCount <= to) {
        setCount(currentCount)
      } else {
        setCount(to)
        clearInterval(intervalId)
      }
    }, 1000 / 60) // 60 FPS로 설정
    return () => clearInterval(intervalId)
  }, [from, to, duration, step])

  return count.toFixed(0)
}
/********************************************************
[사용법]

  const count = useAnimatedCount({from: 0, to, duration: 1000, max_step: 10})
  
  <button
    onClick={() => {
      setTo(randomNumber(100, 300))
    }}>
    <span> {count} </span>
  </button>
*********************************************************/
