/**
 * @name Footer
 * @description
 */
import {Switch} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import {_action} from 'pages/home'

export default function Contents() {
  // hooks
  const dispatch = useAppDispatch()
  const {isAuto} = useAppSelector(state => state.map)
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="toggle">
        <span>{isAuto ? `Auto` : `Pause`}</span>
        <Switch
          isBool={isAuto}
          setIsBool={val => {
            dispatch(_action.setAuto(val))
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.footer`
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 20px;
  /**-------본문--*/
  /* 토글버튼 */
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 48px;
    border-radius: 40px;
    background-color: ${COLOR.WHITE};
    box-shadow: 0px 8px 12px 0px rgba(24, 59, 86, 0.12);
    span {
      display: inline-block;
      margin-right: 8px;
      text-align: center;
      /* Caption/Caption 1 */
      color: #3f598a;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.4px;
    }
  }
`
