/**
 * @name Header
 * @description
 */
import { Image } from "lib";
import styled from "styled-components";
// contents
import { map_logo } from "assets/images";

export default function Contents() {
  //*--------------------------------------------------*
  return (
    <Content>
      <Image src={map_logo} width={280} height={103} />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.header`
  /**-------중앙--*/
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 15px;
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 1000px) {
    header {
      img {
        width: 30%;
      }
    }
  }
`;
