/**
 * @name 구글맵
 * @description
 */
import styled from 'styled-components'
import {GOOGLE_MAP_KEY} from 'config'
import {GoogleMap} from 'lib'
// contents
import {Header, Footer} from './common'
import {sp_link} from 'assets/images'

type Props = {
  mapRef: any
}
export default function Presenter({mapRef}: Props) {
  // hooks
  const info = [
    //---------GRID
    {mode: 'GRID', name: '율촌 2공장', address: '시흥시 군자천로 21번길 46', tel: '031-499-4481', type: 'CVR', startDate: '2024년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '율촌', address: '경기도 시흥시 공단2대로 192', tel: '031-499-4481', type: 'AI융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '대창', address: '경기도 시흥시 공단1대로 391', tel: '031-496-3000', type: 'AI융합', startDate: '2021년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '대창', address: '경기도 시흥시 공단1대로 391', tel: '031-496-3000', type: 'AI바우처', startDate: '2021년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '대창', address: '경기도 시흥시 공단1대로 391', tel: '031-496-3000', type: 'CVR', startDate: '2024년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: 'HLB이노베이션', address: '화성시 향남읍 발안공단로 4길 16', tel: '031-522-3700', type: 'CVR', startDate: '2024년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '세아메카닉스', address: '경북 구미시 1공단로 10길 80', tel: '054-467-2000', type: 'AI융합', startDate: '2022년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '루미너스코리아', address: '경북 구미시 산동면 첨단기업 7로 77-20', tel: '054-974-9710', type: 'AI융합', startDate: '2022년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '금오이엠에스', address: '대구 달성군 현풍읍 테크노대로4길 46', tel: '053-582-2870', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '금오캐스트', address: '대구광역시 달서구 성서공단남로 140', tel: '053-593-2870', type: 'AI융합', startDate: '2022년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: 'CK인더스트리', address: '대구 달서구 성서공단남로 32길 49', tel: '053-584-5608', type: 'AI융합', startDate: '2022년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '성신앤큐', address: '대구 달성군 다사읍 세천로 1길 95', tel: '053-583-1989', type: 'AI융합', startDate: '2022년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '동신금속', address: '대구 달성군 구지면 국가산단대로 39길 203', tel: '053-592-8201', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '경창정공', address: '대구 달성군 구지면 국가산단대로 33길 149', tel: '053-586-2333', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '(주)한국고분자', address: '대구 달성군 구지면 국가산단대로 33길 166', tel: '053-585-8262', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '원화정밀 - 구지공장', address: '대구 달성군 구지면 국가산단대로 49길 156', tel: '053-616-4436', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '원화정밀 - 세천공장', address: '대구 달성군 다사읍 세천로1길 83', tel: '053-352-5863', type: 'AI융합', startDate: '2023년', buttonName: '준비중', content: ''},
    {mode: 'GRID', name: '한영정밀', address: '경상남도 창원시 의창구 평산로 8번길 14', tel: '055-299-5729', type: 'AI융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '대건테크', address: '경남 창원시 의창구 사화로 138', tel: '055-250-8000', type: 'AI융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '서원', address: '경기도 안산시 단원구 산단로 67번길 94', tel: '031-365-8700', type: 'AI융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '대신금속', address: '경남 창원시 의창구 팔용로 346번길 10', tel: '055-296-1011', type: 'AI융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '대신금속', address: '경남 창원시 의창구 팔용로 346번길 10', tel: '055-296-1011', type: '창원 에너지자급자족', buttonName: '집계중', startDate: '2021년', content: ''},
    {mode: 'GRID', name: '알멕', address: '경남 창원시 성산구 공단로 148', tel: '055-260-0400', type: 'AI 융합', startDate: '2021년', buttonName: '집계중', content: ''},
    {mode: 'GRID', name: '신한정밀', address: '대구광역시 달성군 다사읍 세천로2길 60', tel: '053-586-0094', type: 'AI 융합', startDate: '2021년', buttonName: '바로가기', content: ''},
    {mode: 'GRID', name: '코닝정밀소재', address: '충청남도 아산시 탕정면 탕정로 181', tel: '053-586-0094', type: 'AI 융합', startDate: '2018년', buttonName: '운영중', content: ''},
    {mode: 'GRID', name: '양평 수미마을', address: '경기도 양평군 단월면 곱다니길 55-2', type: '마이크로그리드', startDate: '2024년', buttonName: '운영중', content: ''},
    {mode: 'CR', name: 'Olivine (USA)', address: '2120 University Ave.Berkeley CA 94704', tel: '1-888-717-3331', type: 'Green Button', startDate: '2020년', buttonName: '운영중', content: ''},
    //---------EV
    {mode: 'EV', name: '송파농협본점', address: '서울시 송파구 송파대로 155', type: '100kW-2CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '고성 송파농협가족쉼터', address: '강원도 고성군 현내면 금강산로 138', type: '100kW-2CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '서울숲 르씨엘APT', address: '서울시 성동구 금호동4가', type: '100kW-2CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '과천 군부대', address: '경기도 과천시 주암동 300 1068부대', type: '100kW-2CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '김포 (주)케이앤티', address: '경기도 김포시 양촌읍 황금6로 8', type: '100kW-2CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '광주 우체국금융개발원', address: '광주광역시 서구 상무중앙로 110', type: '50kW-1CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '파주 HDC 스튜디오', address: '경기 파주시 파주읍 오봉골길 213', type: '50kW-1CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '신도림 현대백화점 디큐브시티', address: '서울시 구로구 경인로 662', type: '50kW-1CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '강남 분데스언주', address: '서울 강남구 봉은사로 151', type: '100kW-1CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '대전고등학교', address: '대전 중구 대흥로 110', type: '100kW-2CH', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '대전 대신고등학교', address: '대전 서구 오량1길 98', type: '100kW-2CH', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '대전 교육연수원', address: '충남 공주시 반포면 가마봉길 100', type: '100kW-2CH', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '김포 월곶면', address: '경기도 김포시 월곶면 고정로 56', type: '50kW-1CH', startDate: '2022년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '덕은동 주차타워 ', address: '경기도 고양시 덕양구 대덕산로 63', type: '50kW-1CH', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '문경 장례식장 ', address: '경상북도 문경시 산양면 서당마길 7-5', type: '100kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '서울숲 코리아IT센터 ', address: '서울시 성동구 성수일로 12길 26', type: '50kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '천안의료원 ', address: '충남 천안시 동남구 충절로 537', type: '100kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '한마음장애인복지회 ', address: '충남 천안시 동남구 수신면 발산1길 171', type: '100kW,50KW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '일산 셀프세차장 ', address: '경기도 고양시 일산동구 백석동 406-3', type: '100kW,50KW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '중앙대학교 서울캠퍼스 ', address: '서울시 동작구 흑석동 221', type: '100kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '튜닝카성능안전시험센터 ', address: '경북 김천시 어모면 다남리 1468 한국교통안전공단', type: '50kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '용인 고진중학교 ', address: '경기도 용인시 처인구 고림동 635-15', type: '50kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '용인 고진초등학교 ', address: '경기도 용인시 처인구 고림동 639-6', type: '50kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '충남 금성실업 ', address: '충남 당진시 순성면 북창로 115-1', type: '50kW', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'EV', name: '성산포항여객터미널 ', address: '제주특별자치도 서귀포시 성산읍 성산등용로 112-7', type: '전기선박충전기', startDate: '2024년', buttonName: '운영중', content: ''},
    //---------RE
    {mode: 'RE', name: '백두보성 1호기 ', address: '경기도 광주시 곤지암읍 건업리 210', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '백두보성 2호기 ', address: '경기도 광주시 곤지암읍 유사리 248', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '백두보성 3호기 ', address: '경기도 여주시 북내면 가정리 350-1', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '백두보성 4호기 ', address: '경기도 여주시 북내면 가정리 328-1', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '꿈여울 ', address: '전남 무안군 무안읍 창포로 153-64', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '경선버섯 1,2호기 ', address: '경기도 양평군 지평면 수곡로 65', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
    {mode: 'RE', name: '왕림버섯 ', address: '경기도 양평균 지평면 통로골길 36', type: '신재생에너지', startDate: '2023년', buttonName: '운영중', content: ''},
  ]
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 구글맵 */}
      <GoogleMap
        ref={mapRef}
        style={{width: '100%', height: `100vh`}}
        onInit={(e: any) => {}}
        API_KEY={GOOGLE_MAP_KEY}
        onClick={(e: any) => {
          console.log(e)
        }}
        mapOptions={{
          zoom: 9,
          minZoom: 5,
          maxZoom: 20,
          center: {lat: 37.3893801, lng: 127.4140809},
          styles: [
            {featureType: 'poi', stylers: [{visibility: 'off'}]},
            {featureType: 'transit', stylers: [{visibility: 'off'}]},
          ],
        }}
        positions={info}
      />
      {/* 헤더영역 */}
      <Header />
      {/* 푸터영역 */}
      <Footer />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  height: 100vh;
  /**-------infowindow--*/
  .map-infowindow {
    width: 300px;
    padding: 16px 24px;
    /**-------header--*/
    header {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 700;
      color: #183b56;
    }
    /**-------main--*/
    main {
      dl {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        flex-direction: row;
        dt {
          width: 20px;
          /* margin-right: 8px; */
          img {
            width: 16px;
            height: 16px;
          }
        }
        dd {
          color: #183b56;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.4px;
        }
      }
    }
    /**-------footer--*/
    footer {
      display: flex;
      width: 100%;
      padding: 8px 0;
      border-top: 1px solid #b3bac5;
      justify-content: space-between;
      align-items: center;
      h1 {
        color: #5f5f5f;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
      }
      h2 {
        color: #b3bac5;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: -0.18px;
      }
      span.info-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 44px;
        border-radius: 8px;
        border: 1px solid #b3bac5;
        color: #b3bac5;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
        box-shadow: 0px 28px 32px 0px rgba(24, 59, 86, 0.06);
      }
      button.link {
        width: 120px;
        height: 44px;
        background-image: url(${sp_link});
        background-position-y: 0px;
        background-size: 120px 176px;
        -webkit-background-size: 120px 176px;
        /* Shadow/Shadow 1 */
        &:hover {
          background-position-y: -44px;
        }
        &:active {
          background-position-y: -88px;
        }
      }
      /* 전역 스타일 시트 파일 내에 추가 */
      *:focus {
        outline: none;
      }
      /* 특정 클래스에만 적용 */
      .my-custom-marker:focus {
        outline: none;
      }
    }
  }
`
