/**
 * @name useContext
 * @description 글로벌공통으로 사용할수있는 변수,상수,함수를 관리합니다.
 */

import {useAppDispatch, useAppSelector} from 'model'
import {useNavigate} from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import queryString from 'query-string'
import {SESSION_STORAGE} from 'lib/const'

export const useContext = () => {
  // hooks
  const {lang} = useAppSelector(state => state.context)
  const {user, login} = useAppSelector(state => state.auth)
  const {location, sitemap, navi, menu} = useAppSelector(state => state.gnb)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {state} = useLocation() as any // navigate(MENU.TEST, {state: payload}) 사용
  const params = useParams() as any // 라우팅할때 '/:id' 이런식으로 사용
  const query = queryString.parse(window.location.search) // http://192.168.1.45:3000/?intive=code&type=email
  const seq = user?.seq // 로그인이후 고유번호
  const business_seq = sessionStorage.getItem(SESSION_STORAGE.BUSINESS_SEQ) ?? user?.assigned_businesses?.[0]?.business?.business_seq
  const business_code = sessionStorage.getItem(SESSION_STORAGE.BUSINESS_CODE) ?? user?.assigned_businesses?.[0]?.business?.business_code
  // const
  const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
  // *-------------------------------------------------------------------*
  return {query, seq, business_seq, business_code, menu, login, lang, env, navi, navigate, dispatch, state, user, params, sitemap, location}
}
/********************************************************
[사용법]
const {lang}=useContext()
const {state} = useContext()
const {params} = useContext()
const {query} = useContext() // queryString


*********************************************************/
