/**
 *@name 스타일,테마,컬러
 */

/**---------------------------------- @name modules */
export { default as layout } from "./modules/layout.module.scss";
export { default as ui } from "styles/modules/ui.module.scss";

/**---------------------------------- @name etc */
// export
export { COLOR } from "./color";
export { FONT } from "./color";

/**---------------------------------- @name themes */
export { default as ThemesButton } from "styles/themes/button.module.scss";
