/**
 * @name GoogleMap
 * @description 버튼
 */
import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef} from 'react'
import {getLatLng, isLoadedGoogleApi, loadGoogleApi} from './google-map-util'
import {GoogleMapProps} from './google-map'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import {ev_marker, ev_marker_hover, map_marker, map_marker_hover, olivine_marker, olivine_marker_hover, small_location, small_phone} from 'assets/images'
import {pv_marker, pv_marker_hover} from 'assets/svg'

const GoogleMap = forwardRef((props: GoogleMapProps, ref) => {
  // hooks
  const mapElement = useRef(null) //---------------------------------------- 지도를 표시할 div
  const createdMarkers = useRef([]) as any //------------------------------- 생성된 마커들을 저장할 배열
  const infoWindows = useRef<google.maps.InfoWindow[]>([]) //--------------- 마커 클릭시 나타날 정보창
  const markerClusterer = useRef<MarkerClusterer | null>(null) as any //---- 마커그룹화 클러스터

  /************************************
   * @name initMap
   * @description 초기화
   *
   ***********************************/
  const initMap = useCallback(() => {
    //================== 초기화
    const {google} = window
    if (!mapElement.current || !google) return
    infoWindows.current = []
    createdMarkers.current = []
    //================== 지도설정
    const _mapOptions = Object.assign(
      {
        zoom: 12,
        center: {lat: 37.5656, lng: 126.9769},
        zoomControl: false, //---------------------- 줌컨트롤 제거
        fullscreenControl: false, //---------------- 전체화면 제거
        streetViewControl: false, //---------------- 스트리트뷰 제거
        mapTypeControl: false, //------------------- 지도타입(위성,지도) 감추기
      },
      props?.mapOptions,
    )
    const map = new google.maps.Map(mapElement.current, _mapOptions)
    map.addListener('mousemove', (e: google.maps.MapMouseEvent) => !!props?.onMousemove && props?.onMousemove(e))
    map.addListener('click', (e: google.maps.MapMouseEvent) => {
      const _position: any = {lat: e.latLng?.lat(), lng: e?.latLng?.lng(), zoom: map.getZoom()}
      // 클릭된 맵위치에 새로운 마커 추가
      if (props?.isAddMarker) {
        const newMarker = new google.maps.Marker({position: _position, map: map})
        createdMarkers.current.push(newMarker)
        markerClusterer.current.addMarker(newMarker)
      }
      if (props?.isCenter) setCenter(e)
      infoWindows?.current.forEach(window => window.close())
      !!props?.onClick && props?.onClick(_position)
    })
    //================== 클러스터추가및초기화
    markerClusterer.current = new MarkerClusterer({map, markers: createdMarkers.current})

    //================== 마커추가
    if (props?.positions) {
      props?.positions.forEach(async marker => {
        const latlng = await getLatLng(marker?.address)
        if (!latlng?.lat || !latlng?.lng) {
          console.log(`${marker?.address} 위치를 찾을수없습니다.`)
          return
        }
        //# 아이콘세팅
        function setIcon() {
          if (marker.mode === 'GRID') return map_marker
          if (marker.mode === 'EV') return ev_marker
          if (marker.mode === 'RE') return pv_marker
          if (marker.mode === 'CR') return olivine_marker
          return
        }
        //# 아이콘세팅
        function setIconHover() {
          if (marker.mode === 'GRID') return map_marker_hover
          if (marker.mode === 'EV') return ev_marker_hover
          if (marker.mode === 'RE') return pv_marker_hover
          if (marker.mode === 'CR') return olivine_marker_hover
          return
        }
        // 마커이미지
        const googleMarker = new google.maps.Marker({
          position: latlng,
          map: map,
          icon: {
            url: setIcon(),
            scaledSize: new google.maps.Size(36, 51),
          },
        })
        // Event listener for mouseover
        googleMarker.addListener('mouseover', () => {
          googleMarker.setIcon({
            url: setIconHover(),
            scaledSize: new google.maps.Size(36, 51), // Adjust size if needed
          })
        })
        // Event listener for mouseout
        googleMarker.addListener('mouseout', () => {
          googleMarker.setIcon({
            url: setIcon(), // Reset to the original icon
            scaledSize: new google.maps.Size(36, 51),
          })
        })
        // 생성된 마커를 배열에 추가 및 마커 클러스터에 추가
        createdMarkers.current.push(googleMarker)
        //# 클러스터링 제거  markerClusterer.current.addMarker(googleMarker)
        const renderTelInfo = (tel: any) => {
          return tel
            ? `
              <dl>
                <dt><img src="${small_phone}" /></dt>
                <dd>${tel}</dd>
              </dl>`
            : ''
        }
        //# 버튼영역
        const renderButton = (data: any) => {
          if (data === '운영중') return `<span class="info-button">${data}</span>`
          if (data === '집계중') return `<span class="info-button">${data}</span>`

          return `<button class="link info-window-link" data-url="https://acelogrid.com" />`
        }
        const infoWindowContent = `
          <div class="map-infowindow">
            <header>${marker?.name}</header>
            <main>
              <dl>
                <dt><img src="${small_location}" /></dt>
                <dd>${marker?.address}</dd>
              </dl>
              ${renderTelInfo(marker?.tel)}
            </main>
            <footer>
              <section>
                <h1>${marker?.type}</h1>
                <h2>${marker?.startDate}</h2>
              </section>
              ${renderButton(marker?.buttonName)}
            </footer>
          </div>`
        const infoWindow = new google.maps.InfoWindow({content: infoWindowContent}) as any
        //-- InfoWindow에 domready 이벤트 리스너 추가
        google.maps.event.addListener(infoWindow, 'domready', () => {
          !!props?.onInfoWindowDomReady && props?.onInfoWindowDomReady(marker)
        })
        //----
        // 마커에 infoWindow 인스턴스 연결
        marker.infoWindow = infoWindow
        marker.marker = googleMarker
        infoWindows.current.push(infoWindow)
        googleMarker.addListener('click', (data: GoogleMapProps) => {
          // 다른 모든 정보 창 닫기
          infoWindows?.current.forEach(info => info.close())
          infoWindow.open(map, googleMarker)
          // 중앙이동
          setCenter(data)
          // 마커이벤트
          !!props?.onMarkerClick && props?.onMarkerClick(data)
        })
      })
    }
    //================== 지도완료이후초기화함수
    !!props?.onInit && props?.onInit(map)
    //================== window에 저장
    window.googlemap = map
    if (props?.positions) window.googlemap.positions = props?.positions
    //================== 공통함수
    //====== 지도중앙이동
    function setCenter(data: any) {
      !!data && map.panTo({lat: data.latLng.lat(), lng: data.latLng.lng()})
    }
  }, [props?.mapOptions, props?.positions])

  useEffect(() => {
    const listener = (e: any) => {
      if (e.target.classList.contains('info-window-link')) {
        const url = e.target.getAttribute('data-url')
        if (url) {
          window.open(url, '_blank')
        }
      }
    }

    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])
  useEffect(() => {
    // 구글API가 로드되어있으면 바로 initMap 실행
    if (isLoadedGoogleApi()) return initMap()
    window.initMap = initMap
    loadGoogleApi({
      key: props?.API_KEY,
      language: 'ko',
      region: 'KR',
      callback: 'initMap',
    })
  }, [])
  //*--------------------------------------------------*
  return (
    <section style={Object.assign({width: '100%', height: `500px`}, props?.style)} ref={mapElement}>
      {/* 이곳에 지도가 표시됩니다. */}
    </section>
  )
})
export default GoogleMap
