/**
 * @name Code1
 * @description 가이드페이지
 */

import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, Input, debug, useContext} from 'lib'
import {stringify} from 'querystring'

export default function Contents() {
  // hooks
  // const {navi, state, user} = useContext()
  const [message, setMessage] = useState('')
  const [receivedMessage, setReceivedMessage] = useState('')
  const [state, setState] = useState({message: '', name: ''})

  //*--------------------------------------------------*
  useEffect(() => {
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <div>
        <Input onChange={str => {}} />
        <input type="text" value={message} onChange={e => setMessage(e.target.value)} />
      </div>

      <Button
        label="API"
        onClick={async () => {
          // sendMessage()
          // console.log('test')
        }}
      />
      {/* 헤더 */}
      {/* {debug(navi)}
      {debug(state)}
      {debug(user)} */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
/********************************************************
[사용법]


*********************************************************/
