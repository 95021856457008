// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @name Fonts
 * @description
 */
.ui-horizon-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}`, "",{"version":3,"sources":["webpack://./src/styles/scss/ui.scss"],"names":[],"mappings":"AAAA;;;EAAA;AAQA;EACE,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAHF","sourcesContent":["/**\n * @name Fonts\n * @description\n */\n\n//*--------------------------------------------------*\n\n//|------------------ 가로스크롤\n.ui-horizon-scroll {\n  display: flex;\n  flex-wrap: nowrap;\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
