/**
 * @name Navigate
 * @description 페이지이동
 */

import React from 'react'
import {useRoutes} from 'lib'

export type NavigateProps = {
  to: string
  state?: {}
  children?: React.ReactNode
}
export default function NavigateEvent({to, state, children}: NavigateProps) {
  const {navigate} = useRoutes()

  const handleClick = () => {
    !!to && navigate(to, {state: state})
  }

  return <button onClick={handleClick}>{children}</button>
}
/********************************************************
[사용법]
  <Navigate to={MENU.DEMO} state={{title: 'TEST'}}>
    컴포넌트
  </Navigate>

  <Navigate to={MENU.DEMO}>test</Navigate>

*********************************************************/
