/**
 * @name 지도선택
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
// 모드변경 _mode
export const MODE = {}
// 팝업 _popup
export const POPUP = {}
// export
export const EVENT = {
  // 마커선택
  MAP_CLICK: 'MAP_CLICK',
  MARKER_SELECTED: 'MARKER_SELECTED',
  POSITION_CENTER: 'POSITION_CENTER',
}
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  // 시작,종료날짜
  selected: number | null
  // 중앙위치
  centerPosition: {lng: number; lat: number} | null
  [key: string]: any
}
//*--------------------------------------------------*

//
/** @initialState */
export const sliceName = 'map' //변경필요
const initialState: StateType = {
  selected: 0,
  isAuto: true,
  centerPosition: null,
  rank: [],
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 마커선택
    setSelected(state: StateType, action: PayloadAction<any>) {
      state.selected = action.payload
    },
    // 중앙선택
    setCenterPosition(state: StateType, action: PayloadAction<any>) {
      state.centerPosition = action.payload
    },
    // 자동롤링
    setAuto(state: StateType, action: PayloadAction<any>) {
      state.isAuto = action.payload
    },
  },
})
//*--------------------------------------------------*
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
