/**
 * @name FullButton
 * @description 버튼
 */
import {COLOR} from 'styles'
import Button from './button'
import {ButtonProps, CSS} from 'types'

type Props = {
  label?: string
}
type FullButtonProps = ButtonProps & Props

export default function FullButton(props: FullButtonProps) {
  const defaultStyle: CSS = {
    width: '100%',
    padding: '8px 20px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    color: COLOR.WHITE,
    textAlign: 'center',
    borderRadius: '8px',
    backgroundColor: '#4073F4',
    boxShadow: '0px 2px 4px 0px rgba(165, 163, 174, 0.30)',
  }

  return (
    <Button {...props} style={Object.assign({}, defaultStyle, props?.style)}>
      <span>{props?.label || 'FullButton'}</span>
    </Button>
  )
}

/********************************************************
[사용법]
 
*********************************************************/
