/**
 * @name TextButton
 * @description 버튼
 */
import Button from './button'
import {ButtonProps} from 'types'
import {NavigateProps} from 'lib/events/navigate'
import {Navigate} from 'lib'

type Props = {
  label?: string
}
type NavigateButtonProps = NavigateProps & ButtonProps & Props

export default function NavigateButton(props: NavigateButtonProps) {
  return (
    // <Navigate
    <Navigate to={props?.to} state={props?.state}>
      <Button {...props}>
        <span>{props?.label || 'TextButton'}</span>
      </Button>
    </Navigate>
  )
}

/********************************************************
[사용법]

  <Button.Navigate label="Button.Navigate" to={MENU.DEMO} />
  
*********************************************************/
