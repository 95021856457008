/**
 * @name Panel
 * @description 가이드페이지
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'

type Props = {
  style?: CSS //-------------------------------------- 스타일시트
  header?: React.ReactNode //------------------------- Header
  footer?: React.ReactNode //------------------------- Footer
  children?: React.ReactNode //----------------------- Children
  className?: string //------------------------------- 클래스네임
  padding?: string //--------------------------------- 여백
  border?: string //---------------------------------- 테두리
  borderRadius?: string //---------------------------- 곡률
  [key: string]: any
}
export default function Panel({children, header, footer, className, style, border, borderRadius, padding}: Props) {
  return (
    <Content style={style} border={border} padding={padding} borderRadius={borderRadius} className={className}>
      {/* 헤더영역 */}
      {!!header && <header>{header}</header>}
      {/* 본문영역 */}
      <main className="panel_main">{children}</main>
      {/* 푸터영역 */}
      {!!footer && <footer>{footer}</footer>}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.section<Props>`
  overflow: hidden;
  flex-direction: column;
  /* border: 1px solid ${COLOR.GRAY5}; */
  border: ${({border}) => border || '0'};
  border-radius: ${({borderRadius}) => borderRadius || '10px'};
  background: ${COLOR.WHITE};
  /* box-shadow: 0px 3px 10px 0px rgba(6, 25, 56, 0.08); */
  box-shadow:
    0px 2px 1px 0px #fff inset,
    0px 0.5687500238418579px 1.7921874523162842px 0px rgba(12, 20, 33, 0.01),
    0px 1.37109375px 2.9970703125px 0px rgba(12, 20, 33, 0.02),
    0px 2.5999999046325684px 5.037499904632568px 0px rgba(12, 20, 33, 0.02),
    0px 4.31640625px 8.3857421875px 0px rgba(12, 20, 33, 0.02),
    0px 6.581250190734863px 13.514062881469727px 0px rgba(12, 20, 33, 0.03),
    0px 9.455469131469727px 20.894725799560547px 0px rgba(12, 20, 33, 0.03),
    0px 13px 31px 0px rgba(12, 20, 33, 0.04);
  /* 본문영역 */
  > main.panel_main {
    min-height: 100px;
    padding: ${({padding}) => padding || '24px'};
  }
`
/********************************************************
[사용법]
<Panel borderRadius={'0px'}
  header={
    <div>
      <h1>Panel 타이틀 TEST</h1>
    </div>
  }>
  내용들
</Panel>


*********************************************************/
