/**
 * @name Bind
 * @description 최초진입및 언마운트시 실행
 */
import React, {useEffect} from 'react'

type Props = {
  children?: React.ReactNode
  onEnter?: () => void
  onExit?: () => void
}
export default function Bind({children, onEnter, onExit}: Props) {
  //*--------------------------------------------------*
  useEffect(() => {
    // 페이지 진입시 최초 실행
    if (typeof onEnter === 'function') onEnter()
    // 컴포넌트 언마운트시 실행
    return () => {
      if (typeof onExit === 'function') onExit()
    }
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}
/********************************************************
[사용법]

  <Bind
    onEnter={() => {
      console.log('onEnter')
    }}
    onExit={() => {
      console.log('onExit')
    }}>
    <Presenter />
  </Bind>

*********************************************************/
