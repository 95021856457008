/**
 * @name Modal
 */
type Props = {
  modalClose?: boolean
  visible?: boolean
  setVisible?: (bool: boolean) => void | undefined
  onClose?: () => void
  children: React.ReactNode
  contentStyle?: React.CSSProperties
}
export default function Modal({children, modalClose, visible, contentStyle, onClose, setVisible}: Props) {
  return visible ? (
    <div
      onClick={(event: any) => {
        // 모달창 안에 있는 컨텐츠 클릭시 닫히는 이벤트
        if (typeof event.target.className === 'string' && event.target.className.includes('layout')) {
          !!modalClose && !!setVisible && modalClose && setVisible(false)
          !!onClose && onClose()
        }
      }}
      style={contentStyle}>
      {children}
    </div>
  ) : null
}
/********************************************************
[사용법]
import {Modal} from '@components/layout/'

const [visible, setVisible] = React.useState(true)
const [visible1, setVisible1] = React.useState(false)

<Modal visible={visible} setVisible={setVisible}>
  <div>
    <button onClick={() => { setVisible(false) }}>
      모달창1 닫기
    </button>
  </div>
</Modal>
<Modal visible={visible1} setVisible={setVisible1}>
  <div>
    <button onClick={() => { setVisible1(false) }}>
      모달창2 닫기
    </button>
  </div>
</Modal>
*********************************************************/
