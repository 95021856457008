/**
 * @name Load
 * @description
 */

export const loadScript = (url: string) => {
  const firstScript = window.document.getElementsByTagName('script')[0]
  const script = window.document.createElement('script')
  script.src = url
  script.async = true
  script.defer = true
  firstScript?.parentNode?.insertBefore(script, firstScript)
}
