/**
 * @name FormFileUploader
 * @description
 */
import { Button, H5, Image, Text } from "lib";
import React from "react";
import styled from "styled-components";
import { COLOR } from "styles";

type Props = {
  types?: any[]; //---------------------------------File type
  onChange?: (e: any) => void; //-------------------onChange
  style?: React.CSSProperties; //------------------ StyleSheet
  title?: string; //--------------------------------Title
  message?: string; //------------------------------Message
  [key: string]: any;
};
export default function FormFileUploader({
  style,
  onChange,
  types,
  title,
  message,
}: Props) {
  // hooks
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [file, setFile] = React.useState(null) as any;

  //*--------------------------------------------------*
  return <Element style={style}></Element>;
}
//*--------------------------------------------------*
const Element = styled.div`
  .upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border: 2px dashed ${COLOR.GRAY4};
    border-radius: 8px;
  }
`;
/********************************************************
[사용법]

  <FormFileUploader
    types={['zip', 'ZIP']}
    title="업로드할 펌웨어파일을 첨부해주세요."
    message="파일형식은 zip만 업로드 가능합니다."
    onChange={(file: any) => {
      dispatch(_action.setPayload({firmware_file: file}))
    }}
  />
*********************************************************/
