/**
 * @name Top
 */
import React from "react";
import styled from "styled-components";
import { COLOR, FONT } from "styles";
import { Flex, useRoutes } from "lib";
import { MENU } from "config";
import { FaUser, IoApps, FiLogOut } from "assets/icon";
// contents
import TopUtilGroup from "./top-util-group";
type Props = {
  mode?: "auth" | "app" | "page";
  children?: React.ReactNode | JSX.Element[] | undefined;
  [key: string]: any;
};
export default function Top({ mode = "app" }: Props) {
  // hooks
  const { navigate } = useRoutes();
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 전체메뉴 */}
      <TopUtilGroup />
      <button className="round">
        <FaUser color={COLOR.GRAY1} />
      </button>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
  /*-- 사이트맵 --*/
  button {
    margin-left: 20px;
    &.logout {
      display: flex;
      padding: 10px 5px;
      border-radius: 5px;
      align-items: center;
      /* background: ${COLOR.GRAY4}; */
      &:hover label {
        text-decoration: underline;
      }
      label {
        /* display: block; */
        font-family: ${FONT.MAIN};
        font-size: 12px;
        font-weight: 100;
        margin-left: 5px;
      }
    }
    &.round {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: ${COLOR.GRAY4};
    }
  }
`;
